import { BASE_URL, zipURL } from '../../utils/Constants';

function formatFileURL(fileUrl) {
  if (!fileUrl) return "";

  return fileUrl?.startsWith("http") || fileUrl?.startsWith("https")
    ? zipURL !== ""
      ? zipURL + fileUrl?.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/https?:\/\//, "")
      : fileUrl?.replace("/home/myaie/public_html/", "").replace("public/", "")
    : (zipURL !== "" ? zipURL + BASE_URL?.replace(/https?:\/\//, "") : BASE_URL) +
      (fileUrl?.startsWith("/") ? "" : "/") +
      fileUrl?.replace("/home/myaie/public_html/", "").replace("public/", "");
}

export default formatFileURL;
