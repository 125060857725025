import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AssignmentStatus from "../AssignmentStatus";
import AssignmentType from "../AssignmentType";
import AssignmentEaxmStatus from "../AssignmentExamStatus"
import AppealButton from "../AppealButton"
import { SystemIcons } from "../../common/SystemIcons";
import { RenderAttachList } from "../../common/RenderAttachList";
import decimalFormat from "../../common/decimalFormat";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AssessmentDetailTab from "./AssessmentDetailTab";
import { twoDecimalRound } from "../../../utils/commonFunction";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/Constants";
import { setAssessmentReview, setPracAssessmentLaunched } from "../../../store/actions";

const ShowResult = ({ data }) => (
  // data.grade == 1 ? (
  data.assessmentMarkPercentage == 0 ?
    (<div className='ass-status status-dark-red'><i className="fas fa-circle red"></i>Incomplete</div>) :
    data.assessmentMarkPercentage > 49 ?
      (<div className='ass-status status-green'><i className="fas fa-circle green"></i>Competent</div>) :
      (<div className='ass-status status-dark-red'><i className="fas fa-circle yellow" ></i>Not Yet Competent</div>)

  // ) : (<div className='assessments-result-box'>{data.assessmentMarkPercentage}%</div>)
);

const ShowResultPercent = ({ data }) => (
  // data.grade == 1 ? (
  // <div className="percantage-txt">
    data.assessmentMarkPercentage == 0 ?
      (data.status == "Closed-Submitted" ? 'Pending' : 'Incomplete') :
      data.assessmentMarkPercentage > 49 ?
        'Competent' :
        'Not Yet Competent'
  // </div>
)


const Step7 = (props) => {
  const [showFile, setShowFile] = useState(true);
  const [showFeedback, setShowFeedback] = useState(true);
  const { assessmentId } = props;
  const [assignmentDetails, setassignmentDetails] = useState({});
  const [assignmentReload, setAssignmentReload] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const percentage = 66;
  const selectorState = useSelector((state) => state);
  const { userStatus, isWithHeld } = selectorState
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const handleDownload = ()=>{
    const absolutePath = props?.data.assFile.includes("https") ? props?.data.assFile : BASE_URL + "/" + props?.data.assFile.replaceAll("/home/myaie/public_html/","").replace("public/","").replace("null/","");
    const fileName =  props?.data.assFileName;
    const path_size = props?.data.path_size ? props?.data.path_size : null;

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to download the assessment files?",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure',
      html: `Do you want to download assessment files? <br/><br/><b>File Size: ${path_size}</b>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await props?.downloadFile(absolutePath, fileName);
      }
    })

  }

  const getFileName = (url) => {
    const name = url?.split("/").reverse()[0];
    return name;
  }


  const formatFileSize = (size) => {
    if (typeof size === "string" && /[a-zA-Z]/.test(size)) {
      return size; // If size already contains a unit, return as is
    }

    let sizeInBytes = parseFloat(size); // Ensure it's a number
    if (isNaN(sizeInBytes) || sizeInBytes < 0) return "Invalid Size";

    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let index = 0;

    while (sizeInBytes >= 1024 && index < units.length - 1) {
      sizeInBytes /= 1024;
      index++;
    }

    return `${sizeInBytes.toFixed(2)} ${units[index]}`;
  };
  


  const [studentFeedback, setStudentFeedback] = useState([])

  useEffect(() => {
    if (props.data && props.data.og_ass_type == 13 || props.data.og_ass_type == 14) {

      let feedbackFiles = [];

      if (props.data.is_Use_Generated_Evaluation == 1 && props.data.is_generated_feedback == 1) {
        if (Array.isArray(props.data.feedbackFiles) && props.data.feedbackFiles.length) {
          feedbackFiles = [...props.data.feedbackFiles]; // Ensure it's a flat array
        }

        feedbackFiles.push({
          path: props.data.generated_Feedback,
          filePath: props.data.generated_Feedback,
          size: props.data.generated_feedback_size,
          fileName: props.data.generated_Feedback?.split('/').pop() || 'Generated Feedback.pdf', // Extract filename
        });
      } else {
        feedbackFiles = Array.isArray(props.data.feedbackFiles) ? props.data.feedbackFiles : [];
      }

      setStudentFeedback(feedbackFiles);
    }
  }, [props.data]);


  return (
    <>
      <div class="tab-pane fade show active" id="Assessment-Detail">
        <div class="test-series-main-left-blk">
          <div class={`d-flex justify-content-between align-items-center mb-15 ${(props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14) && 'div__flex'}`}>
            <div class="test-series-main-heading icon-add">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span class="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>
            <div class="btn-action-block submission__btn__box">
              {(props.data.quiz_id == 0 && props?.data?.is_download_allowed==1 && props.data.assFile != '') && <button
                  onClick={() =>{handleDownload()}}
                  type="button"
                  class="btn btn-primary mr-2"
                >
                <i class="fal fa-download"></i>&nbsp; Download
              </button>}
              {(props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14) && (props.data.status !== 'Closed Not-Submitted') ? <button
                onClick={() => {
                  dispatch(setAssessmentReview(true))
                  dispatch(setPracAssessmentLaunched(false))
                  props.setStep(5)
                }}
                type="button"
                className={`btn btn-primary mr-3`}
              >
                <i className={"fal fa-eye"}></i>&nbsp;
                Review Submission
              </button> : null}

              <button
                onClick={props.backBtn}
                type="button"
                class="btn btn-primary"
                title="Back"
              >
                <i class="fal fa-chevron-left"></i> Back
              </button>
            </div>
          </div>
          <div class="test-series-main-heading mb-15">
            <h4>Details</h4>
          </div>
          <AssessmentDetailTab data={props.data} quizId={props.quizId} maxAttempt={props.maxAttempt} step={7} toolTip={props.toolTip} user={user}/>
          {((props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14) && ((props.data?.lecturer_remarks !== "" && props.data?.lecturer_remarks !== null) || studentFeedback?.length)) ? <div className="assessment__details__wrapper">
            <div className="custom__accordion__box">
              <div className="accordion__head d-flex align-items-center justify-content-between">
                <div className="head__bar"><i className="fal fa-user-check"></i> Feedback Summary</div>
                <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary">
                  <i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i>
                </button>
              </div>
              <div className={"textseries-content-blk white-brd-blk mb-4 result__feed__section " + (open ? "show" : "")}>
                <div className="feedback__section">
                  <p className="feedback__title"> Feedback:</p>
                  <div className="feedback__content">
                    {props.data?.lecturer_remarks ? <p className="feed__text">{props.data?.lecturer_remarks}</p> : null}
                    <div className="row feedback__files__section pl-0 ml-0">
                      {studentFeedback?.length > 0 &&
                        studentFeedback.map((file) => (
                          <RenderAttachList
                            key={file?.path}
                            fileName={file?.fileName}
                            size={formatFileSize(file?.size)}
                            filePath={file?.path}
                          />
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div> : null}
          <div class="test-series-main-heading mb-15">
            <h4>Result</h4>
            {(props?.data?.student_intake_status !== 3) && (props.maxAttempt > props.totalattempt) && (moment(props.data.close_date_time) >= moment()) &&
              <div>
                <span className="attempts-con">
                  Attempts left: {props.maxAttempt - props.totalattempt}
                </span>
                <span onClick={props.reAttemptQuiz} className={`retry-txt`}>
                  Retry
                </span>
              </div>
            }
          </div>
          {props?.data?.ass_type=="Survey" ? <>
          <div class="innerblue-textseries-content blue-bg-blk">
              <div class="row">
                <div class="col-md-8">
                  <div class="assessment-result-content">
                    <div className="assessment-result-st-blk">
                      <p>
                        <strong>Dear {user?.fullname}</strong>
                      </p>
                      <p>Thank you for submission.</p>                       
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </>
          :<>
            {((props?.data?.grademixCulc?.length > 0 && props?.data?.grademixCulc[0].gradeText == 'witheld') || props?.data?.assCalculateGradeStatus?.gradeText =='witheld') && 
              <div className="result-ticket">
                <div className="result-ticket-inner d-flex justify-content-between">

                <div className="result-ticket-L">
                  <h5>Result have been withheld</h5>
                  <p>Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket below.</p>
                </div>

                <div className="result-ticket-R">
                  <b>Your Result: WITHHELD</b>
                </div>
                </div>

              <Link to="/addnewticket" className="btn-white-bordered-ticket"><i class="far fa-ticket-alt"></i> Log A Ticket</Link>
            </div>}
          {props.gradingStandard == 2 ? <>
          {!(props?.data?.grademixCulc?.length > 0 && props?.data?.grademixCulc[0].gradeText == 'witheld') &&
          <>
          <div class="innerblue-textseries-content blue-bg-blk">
            <div class="row">
              <div class="col-md-4">
                <div class="assessment-result-content">
                  {props.data.status == "Closed-Marked" && props.data.grade == 1 ? 
                  <div class="assessment-result-status">
                    <ShowResult data={props.data} /> 
                  </div>
                  : null}
                  <div className="assessment-result-st-blk">
                    <p>
                      <h4>Your Results</h4>
                      <strong>{user.fullname}</strong>
                    </p>
                    {(props.data.status && (props.data.status.toLowerCase() == "closed not-submitted")) ? <p>You did not submit the assessment</p> :
                      (props.data.status && (props.data.status.toLowerCase() == "closed-submitted")) ? <p>Result pending</p> : <p></p>
                    }
                    <AppealButton onClick={props.openAppealCall} appeal_button={props.data.appeal_button} row_id={props.data.id} />
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="percent-block multiple-circle">
                    {props?.data?.grade === 0 ? <div class="percent">
                      {(props?.data?.grademixCulc || []).map(item => {
                        return (<div class="chart-canvas m-2">
                          <CircularProgressbar
                            styles={buildStyles(item.grade >= 75 ? styles.progressSuccess : styles.progressFail)}
                            value={item.ass_percentage}
                          />
                          {item?.grading_type == 1 ? (
                            <div className="chart-count grade_robot_text">
                              {item.gradeText}
                            </div>
                          ) : (item?.grading_type == 3 ? 
                            <div className="chart-count">
                              {`${item.gradeText}/${item.ass_total_mark}`}
                            </div>
                            :
                            <div className="chart-count">
                              {item.gradeText}
                            </div>
                          )}
                          <div className="text-center mt-2">{item.sor_name}</div>
                        </div>)
                      })}
                    </div>
                      : props.data.grade === 1
                        ? <ShowResultPercent data={props.data} />
                        : props.data.grade === 3
                          ? `${props?.data?.assCalculateGradeStatus?.gradeText != null ? props?.data?.assCalculateGradeStatus?.gradeText : 0} / ${props?.data?.subAssTotMark != null ? props?.data?.subAssTotMark : 0}`
                          : props?.data?.assCalculateGradeStatus?.gradeText
                    }
                </div>
              </div>
            </div>
          </div>
          </>}
          </> :
          !((props?.data?.grademixCulc?.lenght > 0 && props?.data?.grademixCulc[0].grade == 'witheld') || props?.data?.assCalculateGradeStatus?.gradeText =='witheld') && <>
          <div class="innerblue-textseries-content blue-bg-blk">
            <div class="row">
              <div class="col-md-8">
                  <div class="assessment-result-content">
                    {props.data.status == "Closed-Marked" && props.data.grade == 1 ? 
                      <div class="assessment-result-status">
                        <ShowResult data={props.data} /> 
                      </div>
                    : null}
                    
                    <div className="assessment-result-st-blk">
                      <p>
                        <strong>Dear {user?.fullname}</strong>
                      </p>
                      {(props.data.status && (props.data.status.toLowerCase() == "closed not-submitted")) ? <p>You did not submit the assessment</p> :
                        (props.data.status && (props.data.status.toLowerCase() == "closed-submitted")) ? <p>Result pending</p> : <p></p>
                      }
                      <AppealButton onClick={props.openAppealCall} appeal_button={props.data.appeal_button} row_id={props.data.id} type={props.data?.og_ass_type} />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="percent-block mb-4">
                    <h4>Your Result:</h4>
                    {props.data.grade === 0 ? <div class="percent">
                      <div class="chart-canvas">
                          <CircularProgressbar
                            styles={buildStyles(props.data.assessmentMarkPercentage >= 75 ? styles.progressSuccess : styles.progressFail)}
                            value={props.data.assessmentMarkPercentage}
                          />
                        <div class="chart-count">
                          {twoDecimalRound(props?.data?.assCalculateGradeStatus?.grade) + "%"}
                        </div>
                      </div>
                    </div>
                    : props.data.grade === 1 
                      ? <ShowResultPercent data={props.data} /> 
                      : props.data.grade === 3 
                        ? `${props?.data?.assCalculateGradeStatus?.gradeText != null ? twoDecimalRound(props?.data?.assCalculateGradeStatus?.gradeText) : 0} / ${props?.data?.subAssTotMark != null ? props?.data?.subAssTotMark : 0 }`
                        : props?.data?.assCalculateGradeStatus?.gradeText 
                  }
                  </div>
                  {/* <div class="percent-block">
                    <h4>Subject Total / Term Mark:</h4>
                    <div class="percent">
                      <div class="chart-canvas">
                        <CircularProgressbar
                          styles={buildStyles(props.data.assessmentMarkPercentage >= 75 ? styles.progressSuccess : styles.progressFail)}
                          value={props.data.subAssObtainPercentage}
                        />
                        <div class="chart-count">{props.data.subject_total ? parseInt(props.data.subject_total) : '0'}%</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div></>}
          </>}


        </div>
      </div>
    </>
  );
}
export default Step7;


const styles = {
  progress: {
    pathColor: `white`,
    trailColor: `rgba(255,255,255,0.5)`,
  },
  progressFail: {
    pathColor: `white`,
    trailColor: `rgba(255,255,255,0.5)`,
  },
  progressSuccess: {
    pathColor: `white`,
    trailColor: 'rgba(255,255,255,0.5)',
  }

}