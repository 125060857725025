import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import QualificationShortInfo from "./QualificationShortInfo";
//import randomColor from "randomcolor";
import { download } from "../../utils/FileDownload";
import ShortNameBox from "./ShortNameBox";
import { useDispatch, useSelector } from "react-redux";
import { setIsExamLaunched } from "../../store/actions";
import { formatDateRange, getSubjectStatus, subjectDuration } from "../../utils/commonFunction";
import CommonCourseComponents from "./CommonCourseComponents";
import moment from "moment";
import Str from "../common/Str";
import { math } from "@tensorflow/tfjs";
import LearningTabShortInfo from "./LearningTabShortInfo";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { generatePdfForCurriculumNew } from "../../services/QualificationService";
import Swal from "sweetalert2";
import axios from "../../utils/axios";
import { zipURL,ADMIN_PASSWORD } from "../../utils/Constants";
var $ = require("jquery");
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

const QualificationHeadBox = (props) => {
  const { row, showProgress, setShowProgress,updateLearningAssessment} = props;
  const [courseData, setCourseData]=useState({})
  const { progress } = courseData || {};
  const {qid, sid}=useParams()
  const [color, setColor] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { examLaunched } = useSelector(state => state.examLaunched);
  var icon = getSubjectStatus(courseData?.subjectStatus);
  const [downloadLoading, setDownloadLoading]=useState(false)
  const [bookListFileURL, setBookListFileURL]=useState('')
  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
    if ($("body").hasClass("hide-subject-sidebar")) {
      $("body").removeClass("sub-sidebar-c");
    } else {
      $("body").addClass("sub-sidebar-c");
    }
  };

  useEffect(() => {
    //setColor(randomColor());
    if (row.responseData && Object.keys(row.responseData).length > 0) {
      // Code to execute if row.responseData has keys
      setCourseData(row.responseData)
    } else if (row.qualification && Object.keys(row.qualification).length > 0) {
      setCourseData(row?.qualification)
    }
  }, [row]);

   // Utility function to ensure the value is a number
  const toFixedOrDefault = (value, decimals = 2) => {
    const num = Number(value);
    return isNaN(num) ? `0` : num
  };

  const navigateTo = () => {
    if (props.learningTab) {
      return "/mycourses";  // Return the appropriate route
    } else {
      return sid ? `/mycourses/${qid}` : "/mycourses";
    }
  };
  useEffect(() => {
    if (
      !(progress?.total_assessment > 0 &&
        (progress?.total_documents > 0 ||
        progress?.total_links > 0 ||
        progress?.total_quizs > 0 ||
        progress?.total_videos > 0))
    ) {
      setShowProgress && setShowProgress(true);
      updateLearningAssessment && updateLearningAssessment(true);
    }
  }, [progress]);

  const handleDownloadPrescribedBook = () => {
    // setDownloadLoading(true);

    const brandVariable = localStorage.getItem("brandVariables");
    const brandId = brandVariable ? JSON.parse(brandVariable).id : null;

    const payload = {
      brandId: brandId,
      netsuite_id: courseData?.netsuite_id
    };

    Swal.fire({
      icon: "info",
      title: "Downloading...",
      showConfirmButton: false,
      onOpen: () => Swal.showLoading(),
    });

    generatePdfForCurriculumNew(payload)
      .then((res) => {
        if (res.data?.fileName !== "") {
          let fileURL = res.data?.fileName;
          fileURL = zipURL !== ""
            ? zipURL + fileURL
              ?.replace("/home/myaie/public_html/", "")
              .replace("public/", "")
              .replace(/https?:\/\//, "")
            : fileURL?.replace("/home/myaie/public_html/", "").replace("public/", "");
          setBookListFileURL(fileURL);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error while downloading file",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error while downloading file",
          confirmButtonText: "OK",
        });
      })
  };

  useEffect(() => {
    const downloadFile = (fileURL) => {
      return new Promise((resolve, reject) => {
        const fileName = fileURL.split("/").pop();

        fetch(fileURL)
          .then((response) => {
            if (!response.ok) {
              Swal.fire({
                icon: "error",
                title: "Error while downloading file",
                confirmButtonText: "OK",
              });
              return;
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            Swal.fire({
              icon: "success",
              title: "Downloading Finished",
              showConfirmButton: true,
            });
          })
          .catch((error) => {
            console.error("Download error:", error);
            Swal.fire({
              icon: "error",
              title: "Error while downloading file",
              confirmButtonText: "OK",
            });
          });
      });
    };

    if (bookListFileURL !== "") {
      downloadFile(bookListFileURL).catch((error) => {
        console.error("Download error:", error);
        Swal.fire({
          icon: "error",
          title: "File Download Error",
          text: "There was an issue downloading the file.",
          confirmButtonText: "OK",
        });
        Swal.close();
      });
    }
  }, [bookListFileURL]);

  return (
    <>
      <div className="sub-lft-head">
        <div className="all-courses side__nav__btn">
          {history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched
            ? <Link
              onClick={() => dispatch(setIsExamLaunched({ ...examLaunched, isClicked: true }))} title="Back"
            >
              <i className="fal fa-angle-left"></i>Back
            </Link>
            : <Link
              to={props.learningTab
                ? props.isAdmin === 1
                  ? `/courseinfo/${qid}/subject/${sid}/${ADMIN_PASSWORD}`
                  : `/course/${qid}/subject/${sid}`
                : sid
                  ? `/mycourses/${qid}`
                  : "/mycourses"}
                title="Back"
              onClick={() => {
                if (props.learningTab) {
                  props.setLearningTab(false); 
                  props.setshow({ cards: true, list: false, desc: false , question:false})
                }
              }}
            >
              <i className="fal fa-angle-left"></i>Back
            </Link>
          }
          <button
            className="close-btn-sidebar"
            onClick={handleSideBar}
            // style={{top:"auto"}}
            title="Close Side Bar"
          >
            <i className="close-bar close-bar-1"></i>
            <i className="close-bar close-bar-2"></i>
            <i className="close-bar close-bar-3"></i>
          </button>
        </div>

        <div className={(props.learningTab && !props.show.cards) ? "couses-bx-info-top-learning" : "couses-bx-info-top"}>
          {(!props.learningTab || (props.learningTab && props.show.cards))  &&
            <>           
              <div className="couses-subject-head">
                <CommonCourseComponents degree={(courseData?.program_type_name) ?  courseData?.program_type_name: "NA"} />
                <div className="d-flex align-items-center" title={courseData?.subjectStatus}>
                  <img src={icon} alt="" width="16px" height="16px" />
                  <p className="status__text">{courseData?.subjectStatus}</p>
                </div>
              </div>

              <div className="couses-bx-info-right">
                <QualificationShortInfo qualification={courseData || row?.qualification} />
              </div>
            </>
          }

          {(!props.learningTab || (props.learningTab && props.show.cards)) && (
            <>
              {/* <div className="d-flex flex-direction-column align-items-center"> */}
                {false ? (
                  <p className="programme__duration" title={courseData?.courseDuration} months>
                    <span className="couse_i_in">
                      <i className="fal fa-clock" style={{ marginRight: "5px" }}></i>
                    </span>
                    {courseData?.courseDuration} months
                  </p>
                ) : (
                    <p className="programme__duration" title={formatDateRange(courseData?.start, courseData?.end)}>
                    <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                    {courseData?.start && courseData?.end ?
                      formatDateRange(courseData?.start, courseData?.end)
                      : "Invalid Date"}
                    </p>
                )}
              {/* </div> */}

              {/* <div className="grade-overview">
                <p className="tittle">Programme Overview</p>

                <div className="overview">
                  <div className="overview-content">
                    <i className="fal fa-chart-bar"></i>
                    <span className="text">Final Grade</span>
                  </div>

                  <div className="grades">
                    <span className="grade-no">
                      {toFixedOrDefault(courseData?.programCalculate?.programeAssMark, 2)}/{toFixedOrDefault(courseData?.programCalculate?.programeAssTotalMark)}
                    </span>
                    <span className={row.responseData?.programCalculate?.pass_status !== "FAIL" ? "mark-status-pass" : "mark-status-fail"}>
                      {row.responseData?.programCalculate?.pass_status !== "FAIL" ? "Pass" : "Fail"}
                    </span>
                  </div>
                </div>
              </div> */}
             
              <div className="progress-program-qualification">
                {(progress?.total_assessment > 0 &&
                  (progress?.total_documents > 0 ||
                    progress?.total_links > 0 ||
                    progress?.total_quizs > 0 ||
                    progress?.total_videos > 0)) && (
                    <div className="programme">
                      <p
                        className="task-name status"
                        title={`${toFixedOrDefault(row.responseData?.progress?.progressBar, 2)}% Completed`}
                      >
                        {toFixedOrDefault(row.responseData?.progress?.progressBar, 2)}% Completed
                      </p>

                      <div
                        className="progress side__progress"
                        title="Progress percentage"
                      >
                        <div
                          className="progress-bar progress-bar-text"
                          role="progressbar"
                          style={{width: `${toFixedOrDefault((row.responseData?.progress?.progressBar || 5), 2)}%`}}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {toFixedOrDefault(row.responseData?.progress?.progressBar, 2)}%
                        </div>
                      </div>
                    </div>
                  )}

                {showProgress && (
                    <>
                      {(progress?.total_documents > 0 ||
                        progress?.total_links > 0 ||
                        progress?.total_quizs > 0 ||
                        progress?.total_videos > 0) && (
                          <div className="programme">
                            <p className="task-name">Learning Material</p>

                            <div
                              className="progress side__progress"
                              title="Learning Material percentage"
                            >
                              <div
                                className="progress-bar progress-bar-text"
                                role="progressbar"
                                style={{width: `${toFixedOrDefault((row.responseData?.progress?.totalLearningMaterialPercentage || 5), 2)}%`}}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {toFixedOrDefault(row.responseData?.progress?.totalLearningMaterialPercentage, 2)}%
                              </div>
                            </div>
                          </div>
                        )}

                      {progress?.total_assessment > 0 && (
                        <div className="programme">
                          <p className="task-name" style={{ paddingTop: "5px" }}>
                            Assessment Progress
                          </p>

                          <div
                            className="progress side__progress"
                            title="Assessments percentage"
                          >
                            <div
                              className="progress-bar progress-bar-text"
                              role="progressbar"
                              style={{width: `${toFixedOrDefault((row.responseData?.progress?.totalAssessmentPercentage || 5), 2)}%`}}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {toFixedOrDefault(row.responseData?.progress?.totalAssessmentPercentage, 2)}%
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                {(progress?.total_assessment > 0 &&
                  (progress?.total_documents > 0 ||
                  progress?.total_links > 0 ||
                  progress?.total_quizs > 0 ||
                  progress?.total_videos > 0)) && (
                    <button
                      onClick={() => setShowProgress(!showProgress)}
                      className="hide-Detail-progress"
                    >
                      {showProgress ? (
                        <span style={{ padding: "5px 0 0 0" }} title="Hide Detailed Progress">
                          Hide Detailed Progress <i className="fal fa-angle-up" />
                        </span>
                      ) : (
                        <span style={{ padding: "7.5px 0 0 0 " }} title="Show Detailed Progress">
                          Show Detailed Progress <i className="fal fa-angle-down" />
                        </span>
                      )}
                    </button>
                  )}
              </div>

            </>
          )}
        </div>
        {(!props.learningTab || (props.learningTab && props.show.cards)) && courseData?.timeTable && courseData?.timeTable !== "" && courseData?.timeTable !== null ? (
          <button title="Download Timetable" onClick={() => downloadFile(courseData?.file, courseData?.fileName)} className="btn btn-white-bordered download__btn" style={{marginTop:"15px"}}>
            <i className="fal fa-download"></i>Download Timetable
          </button>
        ) : null}
        
        {(!props.learningTab || (props.learningTab && props.show.cards)) && courseData?.prescribed_book_buttton === 1  ? (
          <button title="Download Prescribed Book List" onClick={() => { handleDownloadPrescribedBook() }}className="btn btn-white-bordered download__btn" style={{marginTop:"10px"}}>
            <i className="fal fa-download"></i>Download Prescribed Book List
          </button>
        ) : null}
      </div>
    </>
  );
};

export default QualificationHeadBox;
