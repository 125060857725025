import React, { useEffect, useRef, useState } from 'react';
import UploadSignatureFile from './UploadSignatureFile';
import moment from "moment";
import SignaturePadComponent from './SignaturePadComponent';
import { useSelector } from 'react-redux';
const DigitalSignatureComponent = ({isSignatureSave= null, setIsSignatureSave,setSignItemType, isSignature=false,setIsSignature, signaturePadRef,setsignaturePadRef,fileInputRef,formik,readOnly,readOnlyData,setIsSig,ipAddress,setIpAddress,cDate,setCDate,isCancel ,signature_Type = null, updateDisabled=null, required=true}) => {
  const [signatureType, setSignatureType] = useState(readOnly ? 1 : signature_Type !== null ? signature_Type : 0);
  const [fetchDateNIp, setFetchDateNIp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(null);
  const { isAssessmentReview } = useSelector(state=>state.examLaunched);

  useEffect(()=>{
    if(updateDisabled !== null){
      setIsDisabled(updateDisabled)    
    }
  },[updateDisabled])

  useEffect(() => {
    if(fetchDateNIp && !readOnly){
      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {          
        if(!isSignature){
          Promise.resolve()
          .then(() => {
            setIpAddress(data.ip); 
            formik.setFieldValue('ip', data.ip);
            setCDate(moment(new Date().getTime()).format('DD MMM YYYY, HH:mm'));
            formik.setFieldValue('signature_date', new Date().getTime());
            if (typeof setIsSignatureSave === "function") {
              setIsSignatureSave(1)
          } 
          })
          .then(() => {
            if (isSignatureSave !== null) {
              formik.handleSubmit();
            }
          }).catch((error) => {
            console.error("Error during submission:", error);
          });                  
        } else {
          setIpAddress(readOnlyData?.ip)
          formik.setFieldValue('ip', readOnlyData.ip)
          setCDate(moment(parseInt(readOnlyData?.signature_date*1000)).format('DD MMM YYYY, HH:mm'))
          formik.setFieldValue('signature_date', readOnlyData.signature_date)
        }
      })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });    
    }else if((!readOnly && isSignatureSave == null) || (isSignatureSave == 2) ){
      setIpAddress('')
      setCDate('')
    }
  },[fetchDateNIp , isSignature])

  useEffect(() => {
    if (signature_Type !== null) {
      setSignatureType(signature_Type);
    } else {
      setSignatureType(readOnly ? 1 : 0);
    }
  }, [signature_Type, readOnly]);  

  // useEffect(() => {
  //   setSignatureType(readOnly ? 1 : 0)
  // },[readOnly])

  useEffect(() => {
    if (typeof setIsSignatureSave === "function") {
      setIsSignatureSave(0);
    }
  }, [signatureType]); 
  
  //************************************************* */ if there is no signature in case of old forms then no need to show signature components 
  // the below condition is for this only  *******************************
    return ( 
      readOnly && !readOnlyData?.student_signature ? null : isAssessmentReview && !readOnlyData?.student_signature ? <div className='signature-pad-outer'>
        <div className="mt-3 mb-2">Signature</div>
        <div className="empty__signature__box">No signature available.</div>
      </div> :
      <div className='mb-3'>
        <div className="mt-3 mb-1">Signature{required ? '*' : ''}</div>
        <div className={`${readOnly ? 'd-none' : 'd-flex'} gap-2 mb-1 py-1 ${isDisabled && 'disabled-box disabled'}`}>
          <div className={`border-b-black ${isDisabled && 'disabled-box disabled'} ${isDisabled && signatureType == 1 && 'opacity__low'} }`} style={{ borderBottom: signatureType == 0 ? '1px #467FCF solid' : '0', color: signatureType == 0 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black', cursor: readOnly ? "" : 'pointer' }}
            onClick={() => {
              if (!isDisabled && !fetchDateNIp && !readOnly) {
                setSignatureType(0);
                if (typeof setSignItemType === 'function') {
                  setSignItemType(0)
                }
              }
            }}>
            <i className="fal fa-signature"></i>
            <span className="ml-1">Draw</span>
          </div>
          <div className={`border-b-black ${isDisabled && 'disabled-box disabled'} ${isDisabled && signatureType == 0 && 'opacity__low'}`} style={{ borderBottom: signatureType == 1 ? '1px #467FCF solid' : '0', color: signatureType == 1 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black', cursor: readOnly ? "" : 'pointer' }}
            onClick={() => {
              if (!isDisabled && !fetchDateNIp && !readOnly) {
                setSignatureType(1);
                if (typeof setSignItemType === 'function') {
                  setSignItemType(1)
                }
              }
            }}>
            <i className="fal fa-image"></i>
            <span className="ml-1">Upload Image </span>
          </div>
        </div>
        {signatureType == 0 && (
          <>
            <SignaturePadComponent isSignatureSave={isSignatureSave} setIsSignatureSave={setIsSignatureSave} updateDisabled={isDisabled} readOnlyData={readOnlyData} isSignature={isSignature} setIsSignature={setIsSignature} setIsSig={setIsSig} isCancel={isCancel} readOnly={readOnly} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} signaturePadRef={signaturePadRef} />
            {/* <i className="fal fa-info fa-circle"></i> */}
          </>
        )}
        {signatureType == 1 &&
          <UploadSignatureFile isSignatureSave={isSignatureSave} setIsSignatureSave={setIsSignatureSave} updateDisabled={isDisabled} isSignature={isSignature} setIsSignature={setIsSignature} setIsSig={setIsSig} isCancel={isCancel} readOnly={readOnly} readOnlyData={readOnlyData} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} fileInputRef={fileInputRef} />
        }
        <div className='mt-2'>
          <span><i className='fal fa-info-circle mr-1'></i><b>IP:</b> {(readOnly || isSignature !== null) ? readOnlyData?.ip : ipAddress}</span>
          <span className='ml-2'><b>Date: </b>{(readOnly || isSignature !== null) ? readOnlyData?.signature_date ? moment(parseInt(readOnlyData?.signature_date * 1000))?.format('DD MMM YYYY, HH:mm') : '' : cDate}</span>
        </div>
        {formik?.touched?.student_signature && formik?.errors?.student_signature ? (
          <div className="invalid-feedback d-block">
            {formik.errors.student_signature}
          </div>
        ) : null}
      </div>
    );
}
export default DigitalSignatureComponent;