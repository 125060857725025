import HackTimer from "../../../utils/HackTimer";
import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Str from "../../common/Str";
// import Exam_recording from "../../../pages/object-detection/Exam_recording";
import AssignmentStatus from "../../course/AssignmentStatus";
import AssignmentType from "../../course/AssignmentType";
import { zeroPad } from "react-countdown";
import { SystemIcons } from "../../common/SystemIcons";
import { SysCheckSideIcon } from "../../common/SysCheckSideIcon";
import { RenderAttachList } from "../../common/RenderAttachList";
import Swal from "sweetalert2";
import { AssignmentSubmission, GetAssignmentDetail, SetToFinal, StoreTime } from "../../../services/QualificationService";
import ViewQuestion from "../quiz/ViewQuestionExam";
import { useDispatch, useSelector } from "react-redux";
import { clearQuiz, setIsExamLaunched, setPracAssessmentLaunched, setPracAssessSaved, setQuiz, setQuizComplete } from "../../../store/actions";
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import { getPracticalAssessmentQuizQuestions } from "../../../services/QuizService";
import AssessmentMethods from "../AssessmentMethods";
import SekletonSubjectList from "../../../loaders/SekletonSubjectList";
import moment from "moment";
import { getRandomInt } from "../../../utils/commonFunction";


const Step5 = (props) => {
  const [showFile, setShowFile] = useState(true);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(null);
  const [quizArr, setQuizArr] = useState(props.quizArr);
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [open, setOpen] = useState(true);
  const {quizTotalTime, isTimer} = props;
  const { examLaunched,isAssessmentReview } = useSelector(state=>state.examLaunched);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasBeenSetToFinal, setHasBeenSetToFinal] = useState(false);
  const isExamLaunched = history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const examPopup = () => {dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))};
  const [submitQuizAssessment, setSubmitQuizAssessment] = useState(false);
  const [fileInProgress, setFileInProgress] = useState(0);
  const [fileStatus, setFileStatus] = useState({});
  const [assessmentStatus, setAssessmentStatus] = useState(props.data.status ? props.data.status : "");
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [timeLeft, setTimeLeft] = useState("00:00:00");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [assessementDetails, setAssessmentDetails]=useState({})
  const [assessmentType, setAssessmentType]=useState('')
  const user = useSelector((state) => state.user);
  const[photoFiles, setPhotoFiles] = useState({});
  const [videoFiles, setVideoFiles] = useState({});
  const [docFiles, setDocFiles] =useState({})
  const [lecturerObservations, setLecturerObservations] = useState({questionId:'', value:'', updated:false});
  const [assessementQuestionList, setAssessementQuestionList]=useState([])
  const [practicalAssessmentData, setPracticalAssessmentData]=useState([])
  const [loading, setLoading] = useState(false);  
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [closeDate, setCloseDate] = useState('');
  const [termCondition, setTermCondition]=useState(0)
  const declarationData = {
    question: "Student Declaration",
    qid:'Student Declaration',
    question_name: "Student Declaration",
    question_type: "Student Declaration",
    declarationText: "I/we declare that all the information included in this assignment is my/our original work, apart from where source material has been acknowledged. I/we acknowledge that I/we am/are aware of AIE's Plagiarism Policy and understand the consequences of breaching this policy – as well as any other AIE policy relevant to me/us.",
    herebyText: "I hereby confirm that I have read, understood and accepted all",
    termsAndConditionsLink: "https://www.example.com/terms-and-conditions",
    conclusionText: "as set out in the application form that the above subject selections are correct, and understand that there may be changes made based on the academic outcome."
  };

  const initializeAssessment = () => {
    if (isFirstLoad) {
      setLoading(true);
      setIsFirstLoad(false);
    }
    // props.setIsExamLaunchedToTrue();
    getPracticalAssessmentQuizQuestions({
      is_self_assessed: 0,
      assessment_id: props.assessmentId,
      quizId: props.quizId,
    })
      .then((res) => {
        // Clear Redux state
        if (res.status == 200) {
          let headerData=res.data.header;
          const quizData = {};
          const allQuizIds = [];
          setPracticalAssessmentData(res.data)
          let updatedData;
          const updateStudentDeclaration = {
            ...declarationData, 
            ...headerData 
          };
          updatedData = [...res.data.res, updateStudentDeclaration];
          if(isFirstLoad){
            setTermCondition(res.data.header.is_check_terms_and_conditions)
            setAssessementQuestionList(updatedData);
            dispatch(clearQuiz());
          }
          setAssessmentType(res.data?.header?.ass_type)
          const questionList = updatedData;

          const photoTemp = {};
          const videoTemp = {};
          const docTemp = {};

          questionList.forEach((question) => {
            if (question?.practicalFiles?.length > 0) {
              question.practicalFiles.forEach((file) => {
                const qid = question.qid;

                if (file.type === 0) {
                  photoTemp[qid] = [...(photoTemp[qid] || []), file];
                } else if (file.type === 1) {
                  videoTemp[qid] = [...(videoTemp[qid] || []), file];
                } else {
                  docTemp[qid] = [...(docTemp[qid] || []), file];
                }
              });
            }

          });

          // Batch update state once
          setPhotoFiles((prev) => ({ ...prev, ...photoTemp }));
          setVideoFiles((prev) => ({ ...prev, ...videoTemp }));
          setDocFiles((prev) => ({ ...prev, ...docTemp }));

          if (Array.isArray(updatedData) && updatedData?.length > 0) {
            let updatedQuizData = { ...quizData };

            questionList.forEach((question, index) => {
              try {
                if (!isFirstLoad) {
                  if (question.question_type === 'Student Declaration') {
                    question.is_check_terms_and_conditions = termCondition;
                  }
                }
                updatedQuizData[`qa${question.qid}qa`] = question; 
              } catch (error) {
                console.error(`Error processing question ${index + 1}:`, error);
              }
            });
              // Update Redux state
            dispatch(setQuizComplete(updatedQuizData));
          } else {
            console.error("Invalid or empty res.data.res:", res.data.res);
          }
          // Update UI state
          props.setshow({ question: true });

          // Store time and handle reload
          // StoreTime({
          //   assId: props?.assessmentId,
          //   attempted_no: res.data.header.timeHour, // Using timeHour from header
          //   questions_order: allQuizIds.join(","),
          // })
          //   .then(() => {
          //     // props.setAssignmentReload();
          //   })
          //   .catch((error) => {
          //     console.error("Error storing time:", error);
          //   });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error in assessment initialization:", error);
      });
  };
    useEffect(() => {    
      if ((props.data && props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14))  {
          initializeAssessment();
      }
    }, [props.data]);   
  
  const [dropzoneFileInfo, setDropzoneFileInfo] = useState([]);

  // console.log("props?.data", props?.data)
  

  // useEffect(() => {
  //   console.log("timeLeft", timeLeft)
  //   console.log("hours", hours)
  //   console.log("minutes", minutes)
  //   console.log("seconds", seconds)
  //   console.log("props?.data.remainingDuration", props?.data.remainingDuration)
  // }, [hours, minutes, seconds, timeLeft, props?.data.remainingDuration]);



  useEffect(() => {
    if(props?.submitQuizAssessment){
      setSubmitQuizAssessment(props.submitQuizAssessment);
    }
  }, [props?.submitQuizAssessment]);

  const updateSubmitQuizAssessment = () => {
    props.updateSubmitQuizAssessment();
    setSubmitQuizAssessment(false);
  }

  useEffect(()=>{
    if(props?.data.timeLimitActive && isTimeUp && props?.data.launched === 1 && !hasBeenSetToFinal){
      if(props.data.quiz_id == 0){
        if(fileInProgress<=0 && props.fileSubmitted==true){
          props.setToFinal(true);
          setHasBeenSetToFinal(true);
        }
      } else {
        setSubmitQuizAssessment(true);
        setHasBeenSetToFinal(true);
      }
    } 
  },[props, hasBeenSetToFinal, setHasBeenSetToFinal, isTimeUp]);

  useEffect(() => {
    if (props?.data?.timeLimitActive && props?.data?.launched === 1 && props?.data.remainingDuration > 0 ) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              // props.setAssignmentReload(getRandomInt(5000));
              if(props.data.quiz_id > 0 && !hasBeenSetToFinal){
                // console.log("_____________ Run S5 1")
                // setSubmitQuizAssessment(true);
                setHasBeenSetToFinal(true)
              } else if(!hasBeenSetToFinal) {
                if(fileInProgress<=0 ){
                  if(props.fileSubmitted==true){
                    props.setToFinal(true);
                    setHasBeenSetToFinal(true)                                      
                  }else{
                    $(".submit-file-button").click();                  
                  }
                }else{
                  props.setFileSubmitted(false);
                  setDisableFileUpload(true);
                }                
              }
              clearInterval(myInterval)
            }
            else {
              setHours(hours - 1);
              setMinutes(59)
              setSeconds(59);
            }

          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
      let tId = setTimeout(() => {
        GetAssignmentDetail({ aid })
          .then((res) => {
              setAssessmentDetails(res.data)
              setCloseDate(res.data.close_date_time)
              if (res.data && res.data.remainingDuration > 0) {
                var hoursDifference = ~~(res.data.remainingDuration / 3600);
                var minutesDifference = ~~((res.data.remainingDuration % 3600) / 60);
                var secondsDifference = ~~res.data.remainingDuration % 60;
  
                // setHours(hoursDifference)
                // setMinutes(minutesDifference)
                const remainingTime = calculateTimeLeft(res.data, true);
                setTimeLeft(remainingTime)
                const {hours, minutes, seconds} = calculateTimeLeft(res.data);
                setHours(hours)
                setMinutes(minutes)
                setSeconds(seconds)
              } else {
                const remainingTime = calculateTimeLeft(res.data, true);
                setTimeLeft(remainingTime)
                const {hours, minutes, seconds} = calculateTimeLeft(res.data);
                setHours(hours)
                setMinutes(minutes)
                setSeconds(seconds)
            }
          })
          .catch((error) => { });
      }, 1000);

      return ()=>{clearTimeout(tId)}
    }
  }, []);

  useEffect(() => {

    const timeLeftArray =  timeLeft.split(':').map(Number); // Convert time string to an array of numbers
    let hours = timeLeftArray[0];
    let minutes = timeLeftArray[1];
    let seconds = timeLeftArray[2];
    if (((hours * 60 * 60) + (minutes * 60) + seconds) > 0 && ((hours * 60 * 60) + (minutes * 60) + seconds) <= 180 && props?.data.launched && props?.data.timeLimitActive) {
      if (((hours * 60 * 60) + (minutes * 60) + seconds) === 180 || ((hours * 60 * 60) + (minutes * 60) + seconds) === 60) {
        Swal.fire(`<p style='color:red'> Only ${zeroPad(((hours * 60) + minutes))} minutes left </p>`);
      }
    }    
  }, [timeLeft]);



  const uploadQueuedFiles = (dropzoneFileInfo) => {
    if(dropzoneFileInfo.length && dropzoneFileInfo.some(item => item.status !== 'preparing')){
      autoHandleSubmit(dropzoneFileInfo,dropzoneFileInfo,props.data);
      setDropzoneFileInfo([])
    }
  }

  useEffect(() => {
    if(disableFileUpload){
      uploadQueuedFiles(dropzoneFileInfo)
    }
  },[dropzoneFileInfo,disableFileUpload])

  const autoHandleSubmit = (files, allFiles, row) => {
      // setFileSubmitted(false);
      Swal.close();
      Swal.fire({
        icon: "info",
        title: 'Processing File...',
        text: 'Upload may take a bit longer than usual',
        showConfirmButton: false
      });
      if(files.length){
        const formInputs = new FormData();
        files.map((f, index) => {
          formInputs.append('file_' + index, f.file)
        })
        formInputs.append('assignmentRow1', JSON.stringify(row));
        AssignmentSubmission(formInputs)
          .then((res) => {
            setDropzoneFileInfo([])
            props.setToFinal(true)
            // setAssignmentReload(getRandomInt(5000));
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Submission Received',
              text: 'To Finalize your submission please set the status to Final.',
              showConfirmButton: true
            }).then((result) => {
            });
            // setFileSubmitted(true);
          })
          .catch((error) => {
            if(error.response && error.response.status && error.response.status ==400 && error.response?.data?.alreadySubmitted==true){
              Swal.fire({
                icon: "info",
                title: "Assessment already submitted",
                text: error.response.data.message,
              }).then(()=>{
                // initiateReload()
              })
            }else{
              Swal.fire({
                icon: "error",
                title: "Submission Could not be Completed.",
              }).then((result) => {});
            }
          });
      }else{
        Swal.close();
        // setFileSubmitted(true);
      }
      // stop()
    }

  const intervalRef = useRef(null);

  const getCurrentDropzoneFileInfo = () => dropzoneFileInfo;

  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
  
      // Clear the previous interval before setting a new one
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
  
      if (props.data.timeLimitActive === 1) {
        if (isTimerActive || props.data.launched === 1) {
          
          GetAssignmentDetail({ aid })
            .then((res) => {
              setAssessmentStatus(res.data.status);
              let timeRemaining = calculateTimeLeft(res.data, true);
              intervalRef.current = setInterval(() => {
                if(isTimerActive || props.data.launched === 1){
                const timeLeftArray = timeRemaining.split(':').map(Number); // Convert time string to an array of numbers
                let totalSeconds = timeLeftArray[0] * 3600 + timeLeftArray[1] * 60 + timeLeftArray[2]; // Convert to total seconds
                totalSeconds -= 1; // Decrease total seconds by one
                timeRemaining = `${String(Math.floor(totalSeconds / 3600)).padStart(2, '0')}:${String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0')}:${String(totalSeconds % 60).padStart(2, '0')}`; // Convert back to time string
                }
                setTimeLeft(timeRemaining);
                if (timeRemaining === "00:00:00") {
                  setHours(0);
                  setMinutes(0);
                  setSeconds(0);
                  setIsTimeUp(true);
                  setDisableFileUpload(true);
                  setAssessmentStatus("Closed Not-Submitted");
                  clearInterval(intervalRef.current);
                }
              }, 1000);
            })
            .catch((error) => {});
        }
      } else {
        GetAssignmentDetail({ aid })
          .then((res) => {
            setAssessmentStatus(res.data.status);
            intervalRef.current = setInterval(() => {
              // if (isTimerActive) {
              //   console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
              //   clearInterval(intervalRef.current);
              // }
              const now = new Date().getTime();
              const closeTime = new Date(res.data?.close_date_time).getTime();
              const timeLeftFromCloseTime = closeTime - now;
              if (timeLeftFromCloseTime <= 0) {
                setHours(0);
                setMinutes(0);
                setSeconds(0);
                setIsTimeUp(true);
                setDisableFileUpload(true);
                setAssessmentStatus("Closed Not-Submitted");
    
                // Check if there are files before calling uploadQueuedFiles
                // if (dropzoneFileInfoRef.current && dropzoneFileInfoRef.current.length > 0) {
                  uploadQueuedFiles(dropzoneFileInfoRef.current);
                // }
                console.log("Times up");
                // console.log('dropzone',dropzoneFileInfo.includes(item => item.status != 'preparing'),dropzoneFileInfo)
                // if(dropzoneFileInfo.includes(item => item.status != 'preparing')){
                //   props.onSubmit(dropzoneFileInfo,dropzoneFileInfo);
                // }
                clearInterval(intervalRef.current);
              }
            }, 1000);
          })
          .catch((error) => {});
      }
    }
  
    // Cleanup function to clear the interval when dependency changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isTimerActive, props?.reloadchild]); 
  const dropzoneFileInfoRef = useRef(dropzoneFileInfo);

  // Update the ref whenever dropzoneFileInfo changes
useEffect(() => {
  dropzoneFileInfoRef.current = dropzoneFileInfo;
}, [dropzoneFileInfo]);

  const handleChangeStatus = ({ meta, file }, status) => {
    let tempArr = [...dropzoneFileInfo]
    if(status == 'removed'){
      tempArr = tempArr.filter(item => meta.id != item.meta.id)
    }else if(status == 'preparing'){
      tempArr.push({
        meta: meta,
        file: file,
        status: status,
      })
    }else if(status == 'done') {
      tempArr = tempArr.filter(item => meta.id != item.meta.id)
      tempArr.push({
        meta: meta,
        file: file,
        status: status,
      })
    }
    setDropzoneFileInfo(tempArr)
    
    let fileQueue = {...fileStatus, [meta.id]:status};
    const inProgressStatus = ["preparing", "ready", "ready", "getting_upload_params", "uploading", "restarted"]
    const fileProgressCount = Object.keys(fileQueue).filter(item => inProgressStatus.includes(fileQueue[item])).length;
    setFileInProgress(fileProgressCount);
    if(status!="done" && (props?.data.timeLimitActive && hours==0 && minutes==0 && seconds==0)){
      setDisableFileUpload(true)
    }
    if(inProgressStatus.includes(status)){
      props.setFileSubmitted(false);
    }
    setFileStatus({...fileQueue})    
  }

  useEffect(() => {
    if(disableFileUpload){
      document.querySelectorAll('.dzu-previewButton').forEach(button => button.style.display = 'none')
    }
  },[disableFileUpload,dropzoneFileInfo])

  
  const currentTimeStatus = (h, m, s) => {
    setHours(h)
    setMinutes(m)
    setSeconds(s)
  }

  const TimeLeft = (data) => {
    setIsTimerActive(true); 
    const remainingTime = calculateTimeLeft(data, true);
    setTimeLeft(remainingTime)
  }
  const calculateTimeLeft = (data, string = false) => {
    const now = moment().valueOf();
    const closeTime = new Date(data?.close_date_time).getTime();
    const timeLeftFromCloseTime = closeTime - now;

    // Convert timeHour and timeMin to milliseconds
    const timeLimitInMs = ((data?.timeHour || 0) * 60 * 60 * 1000) + 
                         ((data?.timeMin || 0) * 60 * 1000);
    let timeLeftFromTimeLimit = timeLimitInMs;
    let timeElapsed = now - (data.startTime*1000);
    if(data.startTime > 0){
    timeLeftFromTimeLimit = timeLimitInMs - timeElapsed;
    timeLeftFromTimeLimit = timeLeftFromTimeLimit > timeLimitInMs ? timeLimitInMs : timeLeftFromTimeLimit;
  }
  // console.log("timeLeftFromTimeLimit", timeLeftFromTimeLimit)
  // console.log("timeLeftFromCloseTime", timeLeftFromCloseTime)
    // Return whichever time is lesser
    const finalTimeLeft = Math.min(timeLeftFromCloseTime, timeLeftFromTimeLimit);
    // console.log("finalTimeLeft", finalTimeLeft)
    if (finalTimeLeft < 0) {
      return string ? "00:00:00" : {hours: 0, minutes: 0, seconds: 0};
    }
    // Convert back to HH:MM format
    const hours = Math.floor((finalTimeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((finalTimeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((finalTimeLeft % (1000 * 60)) / 1000);

    return string ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : {hours, minutes, seconds};
  }

  return (
    <>
      <div className="tab-pane fade show active" id="Assessment-Detail">
        <div className="test-series-main-block">
          <div className="test-series-main-left-blk">
            <div className="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span className="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>
            
            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li className="custom-breadcrumb-item inactive">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <i className="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="#" onClick={(e)=>{e.preventDefault()}}>System Check</a>
                    </li>
                  }
                  {props.showIdentification &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="#" onClick={(e)=>{e.preventDefault()}}>Student Identification</a>
                    </li>
                  }
                  <li className="custom-breadcrumb-item inactive">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>Test</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={()=>props.backBtn()} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  {(props.data.quiz_id == 0 && props.data.launched == 0 && !isTimeUp) ? 
                    props.data.assFile != '' ? 
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: true, isOnlyDownload: false});TimeLeft(props.data)}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Launch & Download
                      </button>
                    :   
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: false});TimeLeft(props.data)}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-rocket"></i>}&nbsp; Launch
                      </button>
                  : null }
                  {props.data.quiz_id == 0 && props.data.launched == 1 && props.data.assFile != '' ?
                    <button
                      type="button"
                      class="btn btn-primary"
                      disabled={props?.loader==true}
                      onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: true})}}
                    >
                      {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Download
                    </button>
                  : null}
                </div>
              </div>
            </div>

            <div class="test-series-main-heading mb-15">
              <h4>Details</h4>
            </div>
            <div className="assessment__details__wrapper">
              <div className="custom__accordion__box">
                <div className="accordion__head d-flex align-items-center justify-content-between">
                  <div className="head__bar">
                    {(props?.data?.og_ass_type === 13 || props?.data?.og_ass_type === 14) ? (
                      props?.data?.timeLimitActive === 1 && props.data.isSubmitLeft != 0
                        ? <i className="fal fa-ballot-check"></i>
                        : <i className="fal fa-clock"></i>
                    ) : (
                      <i className="fal fa-ballot-check"></i>
                    )}
                    {props?.data && (props?.data?.og_ass_type == 13 || props?.data?.og_ass_type == 14) ? (
                      "Assessment Information"
                    ) : props?.data?.timeLimitActive === 1 && props.data.isSubmitLeft != 0 ? <div className="timer">Time Limit: <span className="as-text-red">
                      {(hours === 0 && minutes === 0 && seconds === 0)
                        ? `00:00`
                        : timeLeft
                        // : `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + (seconds !== null ? seconds : '0')} `
                      }
                    </span>
                    </div> : "Assessment Information"}
                  </div>
                  <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary">
                    <i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i>
                  </button>
                </div>
            <div className={"textseries-content-blk white-brd-blk mb-4 "  + (open ? "show" : "")}>
              <div className={`row ${props.quizId ? '' : 'mb-30'}`}>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Type:</span>
                        <span className="content">
                          {props?.data?.ass_type &&
                            <AssignmentType ass_type={props?.data?.ass_type ? props.data.ass_type : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Status:</span>
                        <span className="content">
                          {props?.data?.status &&
                            <AssignmentStatus status={props?.data?.status} type={props?.data?.og_ass_type}/>
                          }
                        </span>
                      </li>
                      {props.data && (props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14) && props?.data?.status == 'Open' && <li>
                        <span class="heading">Method:</span>
                        <span class="content">
                          {props.data?.isStudentAssest !== null && props.data?.isStudentAssest !== undefined &&
                            <AssessmentMethods isStudentAssest={props.data?.isStudentAssest} />
                          }
                        </span>
                      </li>}
                      <li>
                        <span className="heading">Condition:</span>
                        <span className="content">
                          <span className="ts-info-act">
                            <SystemIcons data={props.data} />
                          </span>
                        </span>
                      </li>
                      {/* {(props.data?.og_ass_type == 13 || props.data?.og_ass_type == 14) && <li>
                        <span class="heading">Sessions:</span>
                        <span class="content">
                        </span>
                      </li>} */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Open Date:</span>
                        <span className="content">
                          {props?.data?.open_date_time
                            ? props.data.open_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span className="heading">Close Date:</span>
                        <span className="content">
                          {props?.data?.close_date_time
                            ? props.data.close_date_time
                            : ""}
                        </span>
                      </li>
                      {props?.data && (props?.data?.og_ass_type != 13 && props?.data?.og_ass_type != 14) ? <li>
                        {props?.data?.timeLimitActive === 1 && <span className="heading">Time Limit:</span>}
                        {props?.data?.timeLimitActive === 1 && <span className="content">{quizTotalTime}</span>}
                      </li> : null}
                    </ul>
                  </div>
                </div>
                {props?.data && (props?.data?.og_ass_type != 13 && props?.data?.og_ass_type != 14) ? <div className="col-md-12 mt-15">
                  <div className="textseries-info-details">
                    <ul>
                      <li className="">
                        <span className="heading">Submission:</span>
                        {((props.data.Files && (props.data.Files.length > 0)) || props.data.fileName != "") ?
                          <span onClick={() => setShowFile(!showFile)} className="content">
                            List of files <i class={`fal ${showFile ? 'fa-chevron-down' : 'fa-chevron-up'} `}></i>
                          </span>
                          :
                          <span className="content">
                            No attempt
                          </span>
                        }
                      </li>
                          {props.data.ass_type === "Supplementary Exam" && <li className="">
                            <span className="heading">Maximum Mark Achievable: &nbsp;</span>
                            {props.data.maximum_grade_achievable ?
                              <span className="content">
                                {props.data.maximum_grade_achievable}
                              </span>
                              :
                              <span className="content">
                                -
                              </span>
                            }
                          </li>}
                      {showFile && (props.data.Files && (props.data.Files.length > 0) || props.data.fileName != "") ?
                        <div className="uploded-files-block mt-15">
                          <div className="row">
                            {props.data.Files && (props.data.Files.length > 0) ?
                              props.data.Files.map(i => {
                                i.removeF = props.handleRemoveFile
                                i.isInArray = true
                                i.showClose = true
                                return (
                                  RenderAttachList(i)
                                )
                              })
                              :
                              props.data.fileName != "" ?
                                RenderAttachList({ fileName: props.data.fileName, size: props.data.subFileSize, filePath: props.data.filePath, removeF: props.handleRemoveFile, id: props.data.subID, isInArray: false, subFilePath: props.data.subFilePath, showClose: true })
                                :
                                null}
                          </div>
                        </div>
                        : null}
                      {props.quizId && props.data?.og_ass_type !== 13  &&
                        <li className="">
                          <span className="heading">Attempts:</span>
                          <span className="content">
                            {props.maxAttempt ? props.maxAttempt : 0}
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                </div>: null}
              </div>
            </div>
            </div>
            </div>
              {(!props.data.quiz_id && props?.data?.launched === 1) && <div className="textseries-content-blk white-brd-blk mb-4">
                <div className={`profile-doc-upload-blk ${props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? 'final_status_change' : ''}`}>
                  <div className="profile-doc-upload" style={{}}>
                    <Dropzone
                      getUploadParams={props.getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      disabled={disableFileUpload}
                      submitButtonDisabled={disableFileUpload}
                      onSubmit={props.onSubmit}
                      classNames={{ submitButton: 'btn btn-primary submit-file-button', submitButtonContainer: ' btn-upload' }}
                      styles={{ dropzone: { /*minHeight: 225*/ } }}
                      accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf,.xlsx"
                      // SubmitButtonComponent={null}
                      inputContent={
                        <lable>
                          <i className="fas fa-file-upload"></i>
                          <h4>
                            To submit assessment, Drop your file here, or <span>browse</span>
                          </h4>
                          <p>Supports: PDF, DOCX and more</p>
                        </lable>
                      }
                      maxFiles={10}
                      // LayoutComponent={({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
                      //   console.log(input, "---->", previews, '=========>', dropzoneProps);
                      //   if (previews) {
                      //     return React.useMemo(() => <div>
                      //       {previews}

                      //       <div {...dropzoneProps}>
                      //         {files.length < maxFiles && input}
                      //       </div>

                      //       {files.length > 0 && submitButton}
                      //     </div>)
                      //   }
                      // }
                      // }
                      maxSizeBytes="1200MB"
                      submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
                    />
                    {/* {props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? ( */}
                    {props?.data?.Files && props?.data?.Files?.length > 0 && (props.data.subTime == null || props.data.subTime == 0) ? (
                      <div className="btn-action-block examfile-upload-btn">
                        <button
                          onClick={()=>props.setToFinal(false)}
                          type="button"
                          className="btn btn-danger"
                        >
                          {props?.loadFinal==true ? <i className={`fas fa-cog fa-spin`}></i> : <i className="fal fa-check"></i>}&nbsp; Change to Final Status
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
            </div>}
            {!loading && ((props.data.quiz_id && props?.data?.launched == 1) || (props?.data?.og_ass_type == 13 || props?.data?.og_ass_type == 14)) ?
              <ViewQuestion
                getSubContentMethod={props.getSubContentMethod}
                quizId={props.quizId}
                setQuizId={props.setQuizId}
                setshow={props.setshow}
                setrId={props.setrId}
                rId={props.rId}
                quizArr={quizArr}
                aid={props.assessmentId}
                currentTimeStatus={currentTimeStatus}
                initiateReload={props.initiateReload}
                setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
                isSubmiting={props.isSubmiting}
                stop={props.stop}
                // quizTotalTime={setQuizTotalTime}
                assPassMark={props?.assPassMark}
                submitQuizAssessment={submitQuizAssessment}
                updateSubmitQuizAssessment={updateSubmitQuizAssessment}
                assessmentdetails={assessementDetails}
                assessmentType={assessmentType}
                photoFiles={photoFiles}
                setPhotoFiles={setPhotoFiles}
                videoFiles={videoFiles}
                setVideoFiles={setVideoFiles}
                docFiles={docFiles}
                setDocFiles={setDocFiles}
                setLecturerObservations={setLecturerObservations}
                lecturerObservations={lecturerObservations}
                assessementQuestionList={assessementQuestionList}
                initializeAssessment={initializeAssessment}
                setStep={props.setStep}
                setAssignmentReload={props.setAssignmentReload}
                practicalAssessmentData={practicalAssessmentData}
                termCondition={termCondition}
                setTermCondition={setTermCondition}
                setAssessmentLoading={props.setAssessmentLoading}
                setIsFirstLoad={props.setIsFirstLoad}
                closeDate={closeDate}
              />
              : null
            }
            {loading ? <SekletonSubjectList/> : null}
            
            <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15">
              <h4>{Str.detailDes}:</h4>
              {props?.data?.details
                ? ReactHtmlParser(props.data.details)
                : Str.noInstruction}
            </div>
          </div>
          <div className="test-series-main-right-blk">
            <div className="step-sidebar-space">
              <SysCheckSideIcon props={props} />
            </div>

            <div className="test-series-steps">
              <ul>
                <li className="test-series-steps-item active done">
                  <a href="#" onClick={(e)=>{e.preventDefault()}}>
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li className="test-series-steps-item active done">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <span>2</span> <b>System Check</b>
                    </a>
                  </li>
                  : null}
                {props.showIdentification ?
                  <li className="test-series-steps-item active done">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <span>3</span> <b>Identification</b>
                    </a>
                  </li>
                  : null}
                <li className="test-series-steps-item active done">
                  <a href="#" onClick={(e)=>{e.preventDefault()}}>
                    <span>4</span> <b>Launch Exam</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step5;
