import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import formatFileURL from './formatUrl';
const   SignaturePadComponent = ({isSignatureSave,setIsSignatureSave,signaturePadRef, formik, setShowIp, showIp,setIsSig,readOnly,isCancel,isSignature,setIsSignature,readOnlyData,updateDisabled}) => {
    const canvasRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [erasorDisplay, setErasorDisplay] = useState(true);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [tool, setTool] = useState('pencil');
    const [isDisabled, setIsDisabled] = useState(false);

    const [readImage, setReadImage] = useState(null)
    const [imageLoading, setImageLoading] = useState(false);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        if (isSignature !== null && readOnlyData) {
            setImageLoading(true);
            setImageError(false);

            var readImage = formatFileURL(readOnlyData?.student_signature)

            // Create a new image object to test the loading
            if (readImage && !readImage.endsWith('undefined')) {
                const img = new Image();

                img.onload = () => {
                    setImageLoading(false);
                    setReadImage(readImage);
                };

                img.onerror = () => {
                    setImageLoading(false);
                    setImageError(true);
                };

                img.src = readImage;
            }
        }
    }, [isSignature, readOnlyData]);

    useEffect(() => {
        if (isSignature) {
            setShowIp(true);
        }
    }, [isSignature]);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        if(canvas){
            const signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgb(255, 255, 255)',
                penColor: 'rgb(0, 0, 0)'
            });
            signaturePadRef.current = signaturePad;

            const checkEmpty = () => {
                const currentData = signaturePad.toData();
                setUndoStack(currentData);
                setRedoStack([]); // Clear redo stack when new stroke is added
                setIsEmpty(signaturePad.isEmpty());
            };
            signaturePad.addEventListener('endStroke', checkEmpty);

            return () => {
                signaturePad.removeEventListener('endStroke', checkEmpty);
                signaturePad.clear();
            };
        }
    }, [isSignature]);

    // Modified effect to handle disabled state while keeping cancel button clickable
    useEffect(() => {
        if (signaturePadRef.current) {
            if (isDisabled && (isSignatureSave == null) || isDisabled && isSignatureSave != 0) {
                signaturePadRef.current.off();
                const canvas = canvasRef.current;
                const overlay = document.createElement('div');
                overlay.style.position = 'absolute';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.right = '40px'; // Leave space for the cancel button
                overlay.style.bottom = '40px'; // Leave space for the bottom area
                overlay.style.cursor = 'not-allowed';
                overlay.style.zIndex = '1000';
                overlay.className = 'signature-overlay';
                canvas.parentElement.style.position = 'relative';
                canvas.parentElement.appendChild(overlay);

                const isPracticalAssessment = formik.values && 'isSignatureUpload' in formik.values 
                if (isPracticalAssessment){
                    return () => {
                        const canvas = canvasRef.current;
                        if (canvas) {
                            const existingOverlay = canvas.parentElement?.querySelector('.signature-overlay');
                            if (existingOverlay) {
                                existingOverlay.remove();
                            }
                        }
                    }
                }else {
                return () => {
                    const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                    if (existingOverlay) {
                        canvas.parentElement.removeChild(existingOverlay);
                    }
                };
            }
            } else {
                if (!isPracticalAssessment){
                    signaturePadRef.current.on();
                    const canvas = canvasRef.current;
                    const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                    if (existingOverlay) {
                        canvas.parentElement.removeChild(existingOverlay);
                    }
                }
            }
        }
    }, [isDisabled,isSignatureSave]);

    const isPracticalAssessment = formik.values && 'isSignatureUpload' in formik.values 

    useEffect(() => {
        if(isCancel){
            handleCancel();
        }
    },[isCancel])

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setIsEmpty(true);
        setUndoStack([]);
        setRedoStack([]);
    };
    // Convert base64 to Blob with specific image type
    const base64ToFile = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        // Create a File object instead of a Blob
        return new File([u8arr], `signature.${mime.split('/')[1]}`, { type: mime });
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            const dataURL = signaturePadRef.current.toDataURL('image/png', 1.0);
            const binary = base64ToFile(dataURL);
            setShowIp(true);
            setIsDisabled(true);
            formik.setFieldValue('student_signature', binary);
            setIsSig(binary)
            if (typeof setIsSignatureSave === 'function') {
                setIsSignatureSave(1);
            }              
        }
    };

    const handleCancel = () => {
        new Promise((resolve) => {
            setShowIp(false);
            setIsDisabled(false);
            setIsSignature(false);
            setIsSig(null);
            setIsEmpty(true);
            setUndoStack([]);
            setRedoStack([]);
            
            if (typeof setIsSignatureSave === "function") {
                setIsSignatureSave(2)
            }           

            if (signaturePadRef.current) {
                signaturePadRef.current.clear();
            }
            formik.setFieldValue('student_signature', null);
            resolve();
        })
        .then(() => {
            if (isSignatureSave !== null) {
                formik.handleSubmit();
            }
        })
        .catch((error) => {
            console.error("Error during submission:", error);
        });
    };
      

    const erase = () => {
        signaturePadRef.current.penColor = 'rgb(255, 255, 255)';
        setErasorDisplay(false);
        setTool('eraser');
    };

    const draw = () => {
        signaturePadRef.current.penColor = 'rgb(0, 0, 0)';
        setErasorDisplay(true);
        setTool('pencil');
    };

    const undo = () => {
        setShowIp(false);
        const data = signaturePadRef.current.toData();
        if (data.length > 0) {
            const lastStroke = data[data.length - 1];
            const newData = data.slice(0, -1);
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => [...prevStack, lastStroke]);
            setIsEmpty(signaturePadRef.current.isEmpty());
        }
    };

    const redo = () => {
        if (redoStack.length > 0) {
            const strokeToRedo = redoStack[redoStack.length - 1];
            const currentData = signaturePadRef.current.toData();
            const newData = [...currentData, strokeToRedo];
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => prevStack.slice(0, -1));
            setIsEmpty(false);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if(!isSignature){
            if (isDisabled) {
            canvas.style.cursor = 'not-allowed';
            } else if (tool === 'pencil') {
                canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cline x1=\'18\' y1=\'2\' x2=\'22\' y2=\'6\'%3E%3C/line%3E%3Cpath d=\'M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
            } else if (tool === 'eraser') {
                canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpath d=\'m7 21-4.3-4.3c-1-1-1-2.5 0-3.4l9.6-9.6c1-1 2.5-1 3.4 0l5.6 5.6c1 1 1 2.5 0 3.4L13 21\'%3E%3C/path%3E%3Cpath d=\'M22 21H7\'%3E%3C/path%3E%3Cpath d=\'m5 11 9 9\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
            }
        }
    }, [tool, isDisabled, isSignature]);
    return (
        <div className="max-w-md position-relative signature-pad-outer">
            <div className="px-md-30 border border-gray-300 rounded-lg overflow-hidden">
                {isSignature ? <>
                    {imageLoading ? (
                        <div className="text-center p-4">
                            <div className="spinner-border text-primary" role="status">
                            </div>
                        </div>
                    ) : imageError ? (
                        <div className="text-center text-danger p-4">
                            Failed to load image. Please try again.
                        </div>
                    ) : <img src={readImage} alt="Signature" className='signature__image' />}
                </>
                    : <canvas  ref={canvasRef}/>
                }
                <hr className='mt-0'/>
                <div className="my-2 left-0 right-0 text-center position-relative text-gray-400">
                    Sign Above 
                </div>
                <div className={`position-absolute bg-white p-1 p-sm-0 d-flex ${updateDisabled !== null && updateDisabled && 'disabled-box disabled'}`} style={{top: '10px', right: '10px', gap: '10px'}}>
                    <i role='button' onClick={undo} className={`fal fa-undo ${showIp ? 'd-none' : 'd-flex'}`}></i>
                    <i role='button' onClick={redo} className={`fal fa-redo ${showIp ? 'd-none' : 'd-flex'}`}></i>
                    {erasorDisplay ? 
                        <i role='button' onClick={erase} className={`fal fa-eraser ${showIp ? 'd-none' : 'd-flex'}`}></i>
                        :
                        <i role='button' className={`fal fa-pencil ${showIp ? 'd-none' : 'd-flex'}`} onClick={draw}></i>   
                    }
                </div>
            </div>
                <div className={`sig-btns ${readOnly ? 'd-none' : 'd-flex'} my-3 flex-column flex-sm-row gap-2`}>
                    <button className='btn btn-primary' disabled={showIp || updateDisabled !== null && updateDisabled} onClick={saveSignature}>Save Signature</button>
                    <button className={`btn btn-danger ${isPracticalAssessment && !formik.values.student_Signature && 'remove-disabled-box disabled'}`} disabled={isPracticalAssessment && !formik.values.student_Signature} onClick={handleCancel}>Remove Signature</button>
                </div>
        </div>
    );
};

export default SignaturePadComponent;